<template>
	<div>
        <h3 class="mb-3"><span class="text-muted">Категория</span> {{categoryName}}</h3>

		<div class="row" v-if="items === null">
			<div class="col-md-12 text-center">
				<div class="spinner-grow" role="status">
					<span class="sr-only">Загрузка...</span>
				</div>
			</div>
		</div>

		<div v-else>
			<masonry ref="masonry" :cols="{default: 2, 1200: 1}" :gutter="{default: '30px', 1200: '10px'}">
                <div v-for="(item, i) in filteredItems" :key="item.id">
				    <project-card v-if="!item.is_finished" :project="item"/>
                    <project-card-finished v-else :project="item"/>
                </div>
			</masonry>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import log from '@/plugins/log'

import AddProjectModal from '@/components/modal/AddProject'

import ProjectCard from '@/components/project/Card'
import ProjectCardFinished from '@/components/project/FinishedCard'

export default {
	name: 'ProjectListCategory',
	data: () => {
		return {
			items: null,
			unsubsribe: null,
			timers: null
		}
	},
	computed: {
		...mapState([
			'current_timer'
        ]),
        category() {
            return this.$route.params.category
        },
        categoryName() {
            return this.items && this.items.length ? this.items[0].users_categories[0].category.title : ''
        },
		filteredItems() {
			return this.items
		}
	},
	async beforeMount() {
		await this.data()
	},
	beforeRouteLeave(to, from , next) {
		if(typeof this.unsubsribe == 'function') 
			this.unsubsribe()
		next()
	},
	methods: {
		async data() {
			this.unsubsribe = await this.$orm.queryAndSubscribe({
				project: {
					where: { users_categories: { category_id: { _eq: this.category } } },
					order_by: { updated_at: 'desc' },
					fragment: 'list'
				}
			}, ([err, response]) => {
				if(err) {
					log.error('/views/project/list', 'methods.data', '$orm.queryAndSubscribe', err)
					return $.NotificationApp.send("Error", "Can't send request", "top-right", "", "error")
				}
				log.debug('/views/project/list', 'methods.data', '$orm.queryAndSubscribe', response)
				this.items = response

				this.$nextTick(() => {
					this.$refs.masonry.reCalculate();
				});
			})
		},
    },
    watch: {
		'$route.params': async function(to, from) {
			await this.data()
		}
	},
	components: {
		AddProjectModal,
		ProjectCard,
		ProjectCardFinished
	}
}
</script>

<style scoped>
.description {
	white-space: pre-line;
}
.task-list .custom-checkbox label {
	color: rgba(0, 0, 0, 0);
	cursor: pointer;
}
</style>